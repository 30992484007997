.widget_1601501547743 {
  position: relative;
  overflow: hidden;
  background: #000;
}

.widget_1601501547743 .sr-cover-image {
  width: 100%;
  background-image: url("../../../Assets/AboutUsImg/NewHomeImg2.png");
  height: 150%;
  top: -50%;
  background-position: bottom;

  position: absolute;
  background-size: cover;
}

.widget_1601501547743 .sr-cover-image:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: url("../../../Assets/AboutUsImg/NewHomeImg2.png");
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.75);
}

.widget_1601501547743 .sr-cover-inner {
  margin: 0 auto;
}
.widget_1601501547743 .cta-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.widget_1601501547743 .btn-wrapper {
  padding: 0 0.25rem;
}

.close {
  font-size: 2rem;
  cursor: pointer;
}
@media (max-width: 768px) {
  .widget_1601501547743 br {
    display: none;
  }
  .ab {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .close {
    font-size: 2rem;
  }
}
.widget_1601501547743 .height-auto {
  min-height: 0vh;
}
.widget_1601501547743 .height-small {
  padding: 8rem 0;
}
.widget_1601501547743 .height-medium {
  padding: 14rem 0;
}
.widget_1601501547743 .height-large {
  padding: 20rem 0;
}
.widget_1601501547743 .height-full {
  min-height: 100vh;
}

.widget_1601501547743 .description p:last-child {
  margin-bottom: 0;
}
.custom-month-color {
  color: #f16722;
}
/* Custom modal size */

@media (min-width: 600px) {
  .modal-custom-size {
    max-width: 80%;
  }
  .moadl-custom-size-postcard {
    max-width: 50%;
  }
}
/* Modal css */
.modal-order-summary-header {
  background: #e35d22;
  border-radius: 0.5rem 0.5rem 0rem 0rem;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.25);
}
.modal-order-summary-header-text {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.custom-card-border {
  border-radius: 0.5rem;
  background: #fff;
  box-shadow: 0px 0px 11.318px 0px rgba(0, 0, 0, 0.25);
}

.custom-card-border .header {
  padding-top: 0.5rem;
  text-align: left;
  color: #000;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-bottom: 2px solid #f16722;
  display: inline-block;
}

.custom-card-border .header::after {
  content: "";
  display: block;

  width: 0rem;
  padding-top: 5px;
  border-bottom: 2px solid #f16722;
}
.custom-card-border .sub-header {
  padding-top: 1rem;
  color: #000;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.custom-card-border .para {
  padding-top: 0.3rem;
  color: #868686;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.custom-card-border .sub-para {
  padding-top: 0.5rem;
  color: #868686;
  font-size: 0.75rem;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
}

.custom-card-border .custom-text-color {
  color: #e35d22;
  padding-top: 0.3rem;
  text-align: right;
  font-family: "Inter", sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
/* .custom-card-border hr {
  background-color: #868686;
} */
.custom-card-border .sub-header-lock {
  padding-top: 0.8rem;
  width: 100%;
  margin-right: 5px;
  min-width: 0.78rem;
  max-width: 1.5rem;
}
.custom-card-border .sub-header-thumbs-up {
  padding-top: 0.8rem;
  width: 100%;
  margin-right: 5px;
  min-width: 1.13rem;
  max-width: 1.5rem;
}
.custom-card-border .sub-header-speaking-bubbles {
  padding-top: 0.8rem;
  width: 100%;
  margin-right: 5px;
  min-width: 1.4rem;
  max-width: 2.1rem;
}
.custom-card-border .i-understand {
  color: #494949;
  margin-left: 5px;
  text-align: justify;
  font-family: "Inter", sans-serif;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
}

.custom-card-border .checkout-sub-header {
  color: #494949;
  font-family: "Inter", sans-serif;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.custom-card-border .payment-method {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-top: 0.76rem;
  padding: 0.7rem 1rem 0.7rem 1.5rem;
  /* padding: 0.2rem;
  padding-left: 1rem; */
  border-radius: 1.5625rem;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}
.custom-card-border .credit-text {
  color: #494949;
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: absolute;
}
.custom-card-border .credit-form {
  color: #494949;
  text-align: justify;
  font-family: " Inter", sans-serif;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.custom-card-border .input-form {
  height: 2.28rem;
  font-size: 0.97463rem;
  border-radius: 0.24363rem;
  border: 0.487px solid #fff;
  background: #fff;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
}
.custom-close {
  height: 3.3rem;
}
.card-transform {
  transition: 0.3s ease-in-out;
 }
 .card-transform-select{
 
   transition: 0.3s ease-in-out;
 }
 .select-plan-border-orange{
    border: 1px solid #f16722 !important;
 }
 .not-select-plan-border-orange{
   border: 1px solid #e5e7ea !important;
 }
 
 .card-transform-select-default{
   transition: 0.3s ease-in-out;
 }
 .card-transform-select-default .card{
   background-color:white;
   color: black;
   
 }
 .card-transform-select .card{
 background-color: black;
 color: white;
  }

  .custom-hidden{
    display: none;
     }
     .custom-show{
      display: block;
     }

.custom-hidden {
  display: none;
}
.custom-show {
  display: block;
}

.StripeElement {
  display: block;
  margin: 5px 0 0px 0;
  /* max-width: 500px; */
  padding: 10px 14px;
  font-size: 1em;
  font-family: "Source Code Pro", monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
}

/* input::placeholder {
  color: #8e9dad!important;
} */

input:focus,
.StripeElement--focus {
  /* box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px; */
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.FpxBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.StripeElement.PaymentRequestButton {
  height: 40px;
}

.pricing-circle {
  display: flex;
  width: 160px;
  height: 160px;
  background: white;
  border-radius: 100%;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-bottom: 8px;
}

/* @media (max-width: 1052px) {
  .pricing-circle {
    max-width: 110px;
    
  }
} */

@media (min-width: 768px) and (max-width: 1024px) {
  .pricing-card .card-image .pricing-circle {
    width: 125px;
    height: 125px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    padding: 10px;
    box-sizing: border-box;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (max-width: 425px) {
  .pricing-circle {
    max-width: 130px;
    height: 130px;
  }

  .mob-fontsize {
    font-size: 1rem;
  }
}

@media (max-width: 762px) {
  .pricing-card h4 {
    font-size: 1.6rem !important;
  }
  .pricing-circle h6 {
    font-size: 1.6rem !important;
    margin: 15px !important;
  }
}

.show-plan-popup {
  display: block !important;
}
.pricing-circle h5 {
  margin-bottom: 0px;
}
.pricing-circle h6 {
  font-size: 1rem;
  margin: 0px;
}

@media (max-width: 768px) {
  .card-transform.pricing-card .card {
    min-height: 390px;
  }
}

.card-transform.pricing-card .card {
  padding-top: 0px !important;
}

.pricing-card .card {
  padding-bottom: 10px !important;
  min-height: 500px;
}
.pricing-card h4{
  font-size: 1rem;
  white-space: nowrap;
}
.pricing-circle h1{
  color:#f16722;
  margin-bottom: 0px;
}
.pricing-circle .membership{
  font-family: "Inter", sans-serif;
  font-weight: bold !important; 
 font-size: 0.8rem !important;
}
.pricing-circle .color-orange{
  color:#f16722;
  margin-bottom: 0px;
}
.btn-primary.text-white a {
  color: white !important;
}
.subscribe-button-container {
  position: absolute;
  width: 100%;
  bottom: 5px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.current-plan-heading {
  line-height: 1.3rem;
}
.cross-img-black {
  position: absolute;
  right: 20px;
}
.subscription-header-sub-header {
  font-weight: normal;
  font-size: 0.9rem;
}
.cancel-Subscription .btn {
  height: 2rem;
}
/* login When form open in mobile view */
@media only screen and (min-width: 320px) and (max-width: 425px) {
  /* .cancel-Subscription  .custom-btn-mobile{
        font-size: 1.7rem;
    } */
  .cancel-Subscription .btn {
    height: 2rem;
  }
}

.viewtext {
  color: #fff;
  text-decoration: underline;
  font-size: 13px;
}
